import React, { useState } from 'react';
import { Box, Container, Grid, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';  
import DashBoard from '../user/DashBoard';  // Import the DashBoard component
import app from '../../realm-config';

const FooterContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#333333', // dark grey
    color: '#ffffff', // white text
    padding: theme.spacing(4, 0),
}));

const Intro = `Autodin est une plateforme innovante pour la vente et l'achat de voitures d'occasion.
Nous facilitons la mise en ligne, le partage, et la visibilité de vos annonces tout en connectant les acheteurs et les vendeurs de manière simple et efficace.
Découvrez un nouveau moyen de trouver votre prochaine voiture ou de vendre la vôtre sans tracas.`;

const Footer = () => {
    const [isDashboardOpen, setDashboardOpen] = useState(false);

    const handleDashboardOpen = () => {
        setDashboardOpen(true);
    };

    const handleDashboardClose = () => {
        setDashboardOpen(false);
    };

    return (
        <FooterContainer>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Qui sommes-nous
                        </Typography>
                        <div>
                            {Intro.split('\n\n').map((paragraph, index) => (
                                <Typography key={index} paragraph>
                                    {paragraph}
                                </Typography>
                            ))}
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Liens
                        </Typography>
                        <Stack spacing={.8}>
                            <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
                                Home
                            </Link>
                            <Link to="/autodin/RGPD" style={{ color: 'inherit', textDecoration: 'none' }}>
                                RGPD
                            </Link>
                            <Link to="/autodin/ConditionsGenerales" style={{ color: 'inherit', textDecoration: 'none' }}>
                                Conditions Générales
                            </Link>
                            <Link to="/autodin/AcheterTrucsEtAstuces" style={{ color: 'inherit', textDecoration: 'none' }}>
                                Acheter une voiture trucs et astuces
                            </Link>
                            <Link to="/autodin/NeuveOccasion" style={{ color: 'inherit', textDecoration: 'none' }}>
                                Voiture neuve ou occasion?
                            </Link>
                            
                            <Link to="/autodin/NormesCO2" style={{ color: 'inherit', textDecoration: 'none' }}>
                                Tout savoir sur les normes co2
                            </Link>
                            {app.currentUser && (
                                <a href="#" onClick={handleDashboardOpen} style={{ color: 'inherit', textDecoration: 'none' }}>
                                    Tableau de bord
                                </a>
                            )}
                        </Stack>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Contact
                        </Typography>
                        <Typography variant="body2">
                            Email: contact@belcar.com
                        </Typography>
                        <Typography variant="body2">
                            Phone: +32 123 45 67 89
                        </Typography>
                    </Grid>
                </Grid>

                <Box textAlign="center" pt={5} pb={2}>
                    <Typography variant="body2">
                        © {new Date().getFullYear()} Autodin. All rights reserved.
                    </Typography>
                </Box>
            </Container>

            {/* Render the DashBoard as a Dialog */}
            <DashBoard 
                open={isDashboardOpen} 
                handleClose={handleDashboardClose} 
                identity='autodin'
            />
        </FooterContainer>
    );
};

export default Footer;
