import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { createNewNode, createNewEdge } from '../../../MlModels/MlNodeUtils';
import { AppendJSONFlow } from '../../../MlModels/MlAppendJsonFlow';
import MlCanvasDock from './MlCanvasDock';
import MlCanvasControls from './MlCanvasControls';
import QwanyzButton from '../../qwanyz/QwanyzButton';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    useReactFlow,
    Panel,
    Controls
} from 'reactflow';
import 'reactflow/dist/style.css';
import NoteNode from './NoteNode';
import './index.css';
import MlPanelIcon from '../MlPanelIcon';
import {
    Stack,
    Box,
    Menu,
} from '@mui/material';  // Import Menu from Material-UI
import MlCanvasMenu from '../MlCanvasMenu';
import MlCanvasToolBox from './MlCanvasToolBox';
import MlAnimationPanel from '../MlAnimationPanel';
import { useMlCanvas } from '../../../MlModels/MlCanvasProvider';
import { MlBoard } from '../mainlistcomponents';
import MlCanvasRightPanel from './MlCanvasRightPanel';
import HtmlCanvas from './HtmlCanvas';
import { calculateNodeStates } from '../../projectmanagement/tasksUtil';
import { useUser } from '../../../MlModels/UserContext';
import CanvasMenu from '../../qwanyx/CanvasMenu';  // Import your ready-made CanvasMenu
import { useHotkeys } from 'react-hotkeys-hook';  // Import the hook

const flowKey = 'example-flow';
const initialNodes = [];
const initialEdges = [];
const proOptions = { hideAttribution: true };

const htmlDisplay = false;

const MlCanvas = (props) => {
    const {
        viewOnly = false,
        showAnimationPanel = false,
        lock = false,
        showNavigation = true,
        showPlayStop = false
    } = props;

    const [menuPosition, setMenuPosition] = useState(null); // Store the right-click position for ReactFlow area
    const [mlNode, setMlNode] = useState(null);
    const [clipboardData, setClipboardData] = useState({ nodes: [], edges: [] });

    const {
        nodes,
        setNodes,
        onNodesChange,
        edges,
        setEdges,
        onEdgesChange,
        setRfInstance,
        updateContainerSize,
        rfInstance,
        refreshCanvas,
        onMoveEnd,
        saveToDatabase,
        pasteElements,
        cutSelectedElements,
        copySelectedElements,
    } = useMlCanvas();

    const flowContainerRef = useRef(null);

    const handleRightClickInFlow = (event) => {
        event.preventDefault(); // Prevent the browser's default context menu
        setMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    };

    const closeMenu = () => {
        setMenuPosition(null); // Close the menu
    };

    const [isCanvasSetupVisible, setIsCanvasSetupVisible] = useState(false);
    const [isFooterVisible, setIsFooterVisible] = useState(showAnimationPanel);
    const [isCanvasReady, setIsCanvasReady] = useState(false);

    const { setViewport } = useReactFlow();
    const { project } = useReactFlow();

    const [customNodeData, setCustomNodeData] = useState({});

    const { fetchUserData } = useUser();



    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchUserData('qwanyx');
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchData();
    }, [fetchUserData]);

    useEffect(() => {
        setIsFooterVisible(showAnimationPanel);  // Update footer visibility
        setIsCanvasReady(true);  // Once footer visibility is set, mark canvas as ready
    }, [showAnimationPanel]);

    useEffect(() => {
        const updateSize = () => {
            if (flowContainerRef.current) {
                updateContainerSize(flowContainerRef.current.offsetWidth, flowContainerRef.current.offsetHeight);
            }
        };

        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, [updateContainerSize]);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(createNewEdge(params), eds)),
        [setEdges]
    );

    useEffect(() => {
        if (rfInstance && mlNode && mlNode.canvas) {
            rfInstance.fromObject(mlNode.canvas);
            setNodes(mlNode.canvas.nodes || []);
            setEdges(mlNode.canvas.edges || []);
        }
    }, [rfInstance, mlNode, setNodes, setEdges]);



    const updateNodeData = useCallback((id, newData) => {
        setNodes((prevNodes) => {
            return prevNodes.map((node) => {
                if (node.id === id) {
                    return { ...node, data: newData };
                }
                return node;
            });
        });
    }, [setNodes]);

    const computeUpdateNodeStates = useCallback(
        (id, newData) => {
            setNodes((prevNodes) => {
                const updatedNodes = prevNodes.map((node) => {
                    if (node.id === id) {
                        return { ...node, data: newData };
                    }
                    return node;
                });

                const recalculatedNodes = calculateNodeStates(id, newData.state, updatedNodes, edges);
                return recalculatedNodes;
            });
        },
        [setNodes, edges]
    );

    const nodeTypes = useMemo(
        () => ({
            selectorNode: (node) => (
                <NoteNode {...node} updateNodeData={viewOnly ? updateNodeData : computeUpdateNodeStates} viewOnly={viewOnly} />
            ),
        }),
        [updateNodeData, computeUpdateNodeStates, viewOnly]
    );

    const lastMousePosition = useRef({ x: 0, y: 0 });
    useEffect(() => {
        const handleMouseMove = (e) => {
            lastMousePosition.current = { x: e.clientX, y: e.clientY };
        };

        const handleKeyDown = async (e) => {
            if (e.ctrlKey && e.key.toLowerCase() === 'u') {
                e.preventDefault();
                const task = e.shiftKey;
                try {
                    const clipboardText = await navigator.clipboard.readText();
                    const json = JSON.parse(clipboardText);
                    if (!Array.isArray(json) || !json.every(item => 'title' in item && 'brief' in item)) {
                        console.error("Invalid JSON format");
                        return;
                    }
                    const position = project({ x: lastMousePosition.current.x, y: lastMousePosition.current.y });
                    const { nodes: newNodes, edges: newEdges } = AppendJSONFlow(json, nodes, edges, task, null, position.x, position.y);
                    setNodes(newNodes);
                    setEdges(newEdges);
                } catch (err) {
                    console.error("Failed to parse JSON", err);
                }
            }
            if (e.altKey && e.key === 'n') {
                e.preventDefault();
                const position = project({ x: lastMousePosition.current.x, y: lastMousePosition.current.y });
                const newNode = createNewNode(position);
                setNodes((nds) => nds.concat(newNode));
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [nodes, edges, setNodes, setEdges, project]);

    // Hotkey for 'Ctrl + S' to save
    // Hotkey for 'Ctrl + S' to save
    useHotkeys(
        'ctrl+s',
        (event) => {
            event.preventDefault();  // Prevent default browser behavior (save webpage)
            saveToDatabase();        // Call the save function
        },
        { enableOnTags: ['INPUT', 'TEXTAREA'] }  // Ensure it works globally
    );

    // Hotkey for 'Alt + C' to copy elements
    useHotkeys(
        'ctrl+shift+c',
        (event) => {
            event.preventDefault();  // Prevent any default behavior
            copySelectedElements((selectedNodes, selectedEdges) => {
                setClipboardData({ nodes: selectedNodes, edges: selectedEdges });  // Store selected elements in clipboard
            });
        },
        { enableOnTags: ['INPUT', 'TEXTAREA'] }
    );

    // Hotkey for 'Alt + X' to cut elements
    useHotkeys(
        'ctrl+shift+x',
        (event) => {
            event.preventDefault();  // Prevent any default behavior
            cutSelectedElements((selectedNodes, selectedEdges) => {
                setClipboardData({ nodes: selectedNodes, edges: selectedEdges });  // Store selected elements in clipboard
            });
        },
        { enableOnTags: ['INPUT', 'TEXTAREA'] }
    );

    // Hotkey for 'Alt + V' to paste elements
    useHotkeys(
        'ctrl+shift+v',
        (event) => {
            event.preventDefault();  // Prevent any default behavior
            if (clipboardData.nodes.length > 0 || clipboardData.edges.length > 0) {
                pasteElements(clipboardData.nodes, clipboardData.edges);  // Paste the stored elements
                setClipboardData({ nodes: [], edges: [] });  // Clear clipboard after pasting
            }
        },
        { enableOnTags: ['INPUT', 'TEXTAREA'] }
    );


    const noOp = () => { };
    const textColor = 'rgb(250,250,248)';
    const headerBgColor = 'rgb(80,80,85)';

    return (
        <MlBoard>
            <style>
                {`
                    .react-flow__pane {
                        cursor: pointer !important;
                    }
                `}
            </style>
            <Stack
                direction="row"
                sx={{ zIndex: 1000 }}
                alignItems="center"
                backgroundColor={headerBgColor}
            >
                <MlCanvasToolBox viewOnly={viewOnly} lock={lock} />
                <Stack direction="row" width="100%" alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }}>
                    <MlCanvasDock viewOnly={viewOnly} color={textColor} />
                </Stack>
                {!viewOnly && (
                    <>
                        <QwanyzButton />
                        <MlCanvasMenu aria-label="Menu" color={textColor} />
                    </>
                )}
                {!viewOnly && (
                    <>
                        <MlPanelIcon initialState={isFooterVisible} onToggle={setIsFooterVisible} color={textColor} panel="bottom" />
                        <MlPanelIcon initialState={isCanvasSetupVisible} onToggle={setIsCanvasSetupVisible} color={textColor} panel="right" />
                    </>
                )}
            </Stack>
            <Stack direction="row" height="calc(100% - 39px)" width="100%" ref={flowContainerRef}>
                <Stack direction="column" width="100%" onContextMenu={handleRightClickInFlow}> {/* Attach right-click handler to this Stack */}
                    {htmlDisplay ? (
                        <HtmlCanvas />
                    ) : (
                        isCanvasReady && (  // Only render ReactFlow when the canvas is ready
                            <ReactFlow
                                minZoom={0.01}
                                maxZoom={10}
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={viewOnly ? noOp : onNodesChange}
                                onEdgesChange={viewOnly ? noOp : onEdgesChange}
                                onConnect={viewOnly ? noOp : onConnect}
                                nodesDraggable={!viewOnly}
                                panOnScroll={false}
                                nodesConnectable={!viewOnly}
                                nodeTypes={nodeTypes}
                                onInit={(reactFlowInstance) => {
                                    setRfInstance(reactFlowInstance);
                                }}
                                zoomOnScroll={!lock}
                                zoomOnPinch={!lock}
                                panOnDrag={!lock}
                                proOptions={proOptions}
                            />
                        )
                    )}
                    {isFooterVisible && (
                        <Box width="100%" height="80px" backgroundColor={headerBgColor}>
                            <MlAnimationPanel showNavigation={false} showPlayStop={true} />
                        </Box>
                    )}
                </Stack>
                {isCanvasSetupVisible && <MlCanvasRightPanel />}
            </Stack>

            {/* Right-click menu for changing node colors using CanvasMenu */}
            <Menu
                open={menuPosition !== null}
                onClose={closeMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    menuPosition !== null
                        ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
                        : undefined
                }
            >
                <CanvasMenu onClose={closeMenu} /> {/* Using your ready-made CanvasMenu component */}
            </Menu>
        </MlBoard>
    );
};

export default MlCanvas;
