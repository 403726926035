import React from 'react';

export const QStack = ({ backgroundColor = 'lightgrey', children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,           // Fills remaining space
        backgroundColor,        // Sets the background color
        height: '100%',         // Ensures it fills parent’s remaining height
        width: '100%',          // Optional: fill width of parent
      }}
    >
      {children}
    </div>
  );
};


